import React, { useCallback, useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./login.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import RegistrationLogout from '../../components/common/RegistrationLogout';
function RegisterDetail2() {
    const [value, setValue] = useState(dayjs(null));
    const [value2, setValue2] = useState(dayjs(null));
    const [value3, setValue3] = useState(dayjs(null));
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [citizenshipStatuses, setCitizenshipStatuses] = useState([]);
    const [maxDate, setMaxDate] = useState('');
    const [submitloader, setSubmitloader] = useState(false);
    const [opendata, setOpendata] = useState(false);
    const [opendata2, setOpendata2] = useState(false);
    const [opendata3, setOpendata3] = useState(false);
    const [opendata4, setOpendata4] = useState(false);
    useEffect(() => {
        const today = dayjs();
        setMaxDate(today.format('YYYY-MM-DD'));
      }, []);
      const [fileNames, setFileNames] = useState({
        i94_copy: "",
        proof_of_work_authorization: "",
        profile: "",
    });
    const [formData, setFormData] = useState({
        citizenship_status: '',
        awaiting_ead_card: '',
        ead_card_applied_date: '',
        citizenship_explanation: '',
        legally_authorized_to_work: '',
        ssn_issue_year: '',
        work_authorization_expiry: '',
        renew_authorization_work:'',
        laws_and_regulations: '',
        i94_copy: null,
        proof_of_work_authorization: null,
        profile: null,
    });

    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const isFirstRender = useRef(true);
    const refetchFormData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await apiService.postRequest('auth/user/history');
            if (response && response.success && response.data.immigration_data ) {
                const immigrationData = response.data.immigration_data;
                const normsData = response.data;
                setFormData((prevData) => {
                    // Create the new form data object
                    const newFormData = {
                        ...prevData,
                        citizenship_status: immigrationData.citizenship_status || '',
                        awaiting_ead_card: immigrationData.awaiting_ead_card || '',
                        ead_card_applied_date: immigrationData.ead_card_applied_date || '',
                        citizenship_explanation: immigrationData.citizenship_explanation || '',
                        legally_authorized_to_work: immigrationData.legally_authorized_to_work || '',
                        ssn_issue_year: immigrationData.ssn_issue_year || '',
                        work_authorization_expiry: immigrationData.work_authorization_expiry || '',
                        renew_authorization_work: immigrationData.renew_authorization_work || '',
                        laws_and_regulations: true || '',
                        i94_copy: immigrationData.i94_copy ? immigrationData.i94_copy : null,
                        proof_of_work_authorization: immigrationData.proof_of_work_authorization ? immigrationData.proof_of_work_authorization : null,
                        profile: normsData.profile ? normsData.profile : null,
                    };
                    setFileNames({
                        i94_copy: immigrationData.i94_copy ? immigrationData.i94_copy.split('/').pop() : '',
                        proof_of_work_authorization: immigrationData.proof_of_work_authorization ? immigrationData.proof_of_work_authorization.split('/').pop() : '',
                        profile: normsData.profile ? normsData.profile.split('/').pop() : ''
                    });
                    // Validation Logic for Citizenship Status
                    const validationErrors = {};
                    const citizenshipStatus = newFormData.citizenship_status;
    
                    if (!citizenshipStatus) {
                        validationErrors.citizenship_status = ['Citizenship status is required'];
                    } else {
                        delete validationErrors.citizenship_status;  // Remove error if valid
                    }
                    
                    let openData3 = false;
                    let openData4 = false;
                    if (newFormData.legally_authorized_to_work === 'yes' && 
                        citizenshipStatus !== 'USC' && 
                        citizenshipStatus !== 'GC') {
                        openData3 = true;
                    } else if (newFormData.legally_authorized_to_work === 'no') {
                        openData4 = true;
                    } else {
                        newFormData.work_authorization_expiry = '';
                    }
                    
                    setOpendata3(openData3);
                    setOpendata4(openData4);
    
                    
                    if (citizenshipStatus === 'OPT EAD' || citizenshipStatus === 'GC EAD' || citizenshipStatus === 'H4 EAD') {
                        setOpendata(true);
                    } else {
                        setOpendata(false);
                        setOpendata2(false);
                        newFormData.awaiting_ead_card = '';
                        newFormData.ead_card_applied_date = '';
                        delete validationErrors.awaiting_ead_card;
                        delete validationErrors.ead_card_applied_date;
                    }
    
                    // Validation Logic for Awaiting EAD Card
                    const awaitingEadCard = newFormData.awaiting_ead_card;
                    if (awaitingEadCard === 'yes') {
                        setOpendata2(true);
                    } else {
                        setOpendata2(false);
                        newFormData.ead_card_applied_date = ''; // Clear the ead_card_applied_date if not awaiting
                        delete validationErrors.ead_card_applied_date; // Remove error if any
                    }
    
                    if (immigrationData.renew_authorization_work) {
                        setOpendata4(true);
                    } else {
                        setOpendata4(false);
                        setFormData(prevState => ({
                            ...prevState,
                            renew_authorization_work: '',
                        }));
                    }
                    return newFormData;
                });
    
                // Handling date parsing after setting new form data
                if (immigrationData.ead_card_applied_date) {
                    const parsedEad = dayjs(immigrationData.ead_card_applied_date, 'YYYY-MM-DD');
                    if (parsedEad.isValid()) {
                        setValue2(parsedEad); 
                    }
                }
                if (immigrationData.work_authorization_expiry) {
                    const parsedWorkexp = dayjs(immigrationData.work_authorization_expiry, 'YYYY-MM-DD');
                    if (parsedWorkexp.isValid()) {
                        setValue(parsedWorkexp);
                    }
                }
                if (immigrationData.ssn_issue_year) {
                    const yearDate = dayjs().year(immigrationData.ssn_issue_year);
                    setValue3(yearDate);
                }
    
            } else {
                
            }
        } catch (error) {
            console.log(error)
        }finally{
            setLoading(false);
        }
    }, []);
    useEffect(() => {
        if (location.state && location.state.fromNextPage) {
            refetchFormData();
        } else {
            isFirstRender.current = false;
        }
    }, [location, refetchFormData]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
    
        // Update the form data state
        if (type === 'radio' || type === 'checkbox') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: type === 'radio' ? value : checked,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    
        // Remove error once the user fills the field correctly
        let validationErrors = { ...errors };
    
        // Validate Citizenship Status
        if (name === 'citizenship_status') {
            if (!value) {
                validationErrors.citizenship_status = ['Citizenship status is required'];
            } else {
                delete validationErrors.citizenship_status;  // Remove error if valid
            }
    
            if (value === 'OPT EAD' || value === 'GC EAD' || value === 'H4 EAD') {
                setOpendata(true);
            } else {
                setOpendata(false);
                setOpendata2(false);
                setFormData(prevData => ({
                    ...prevData,
                    awaiting_ead_card: '',
                    ead_card_applied_date: ''
                }));
                delete validationErrors.awaiting_ead_card;
                delete validationErrors.ead_card_applied_date;
            }
        }
    
        // Validate Awaiting EAD Card
        if (name === 'awaiting_ead_card') {
            if (value === 'yes') {
                setOpendata2(true);
            } else {
                setOpendata2(false);
                setFormData(prevData => ({
                    ...prevData,
                    ead_card_applied_date: ''
                }));
                delete validationErrors.ead_card_applied_date;
            }
        }
    
        // Validate Explanation of Citizenship
        if (name === 'citizenship_explanation') {
            if (!value) {
                validationErrors.citizenship_explanation = ['Please explain your citizenship status'];
            } else {
                delete validationErrors.citizenship_explanation;
            }
        }
    
        // Validate Legal Authorization to Work
        if (name === 'legally_authorized_to_work') {
            if (!value) {
                validationErrors.legally_authorized_to_work = ['Please select if you are legally authorized to work'];
            } else {
                delete validationErrors.legally_authorized_to_work;
            }
        }

        if (name === 'laws_and_regulations') {
            delete validationErrors.laws_and_regulations;
        }
        
        setErrors(validationErrors);
    };
    useEffect(() => {
        if (formData.legally_authorized_to_work === 'yes' && 
            formData.citizenship_status !== 'USC' && 
            formData.citizenship_status !== 'GC') {
            setOpendata3(true);
            setOpendata4(false);
        }else if (formData.legally_authorized_to_work === 'no'){
            setOpendata4(true);
            setOpendata3(false);
        } else {
            setOpendata3(false);
            setOpendata4(false);
            setFormData(prevData => ({
                ...prevData,
                work_authorization_expiry: ''
            }));
        }
    }, [formData.legally_authorized_to_work, formData.citizenship_status]);
    const handleDateChange = (newValue) => {
        if (newValue) {
            setValue2(newValue);
            setFormData({ ...formData, ead_card_applied_date: newValue.format('YYYY-MM-DD') });
    
            // Clear the error for dob if a valid date is entered
            if (errors.ead_card_applied_date) {
                setErrors({ ...errors, ead_card_applied_date: '' });
            }
        } else {
            setValue2(null);
            setFormData({ ...formData, ead_card_applied_date: '' });
        }
    };
    useEffect(() => {
        if (formData.work_authorization_expiry) {
            const newValue = dayjs(formData.work_authorization_expiry, 'YYYY-MM-DD');
    
            if (newValue.isValid()) {
                const sixMonthsFromNow = dayjs().add(6, 'month');
                if (newValue.isBefore(sixMonthsFromNow)) {
                    setOpendata4(true);
                } else {
                    setOpendata4(false);
                    setFormData(prevState => ({
                        ...prevState,
                        renew_authorization_work: '',
                    }));
                }
            }
        } else {
            setOpendata4(false); // Reset if work_authorization_expiry is empty
        }
    }, [formData.work_authorization_expiry]); // Dependency array to trigger the effect on change
    
    const handleDateChange2 = (newValue) => {
        if (newValue) {
            setValue(newValue);
            const formattedDate = newValue.format('YYYY-MM-DD');
            setFormData(prevState => ({
                ...prevState,
                work_authorization_expiry: formattedDate,
            }));
    
            if (errors.work_authorization_expiry) {
                setErrors({ ...errors, work_authorization_expiry: '' });
            }
        } else {
            setValue(null);
            setFormData(prevState => ({
                ...prevState,
                work_authorization_expiry: '',
            }));
            setOpendata4(false); // Reset if the date is cleared
        }
    };
    const handleDateChange3 = (newValue) => {
        if (newValue) {
            setValue3(newValue);
            setFormData({ ...formData, ssn_issue_year: newValue.format('YYYY') });

            if (errors.ssn_issue_year) {
                setErrors({ ...errors, ssn_issue_year: '' });
            }
        } else {
            setValue3(null);
            setFormData({ ...formData, ssn_issue_year: '' });
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            const response = await apiService.postRequest('generalsettings-data');
            if (response.success) {
                setCitizenshipStatuses(response.citizenship_statuses);
            }
        };
        fetchData();
    }, []);

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (files.length > 0) {
            const file = files[0]; // Get the selected file

            // Update the formData state with the new file
            setFormData((prevData) => ({
                ...prevData,
                [name]: file, // Store the file object for submission
            }));
            
            // Update the fileNames state to show the file name
            setFileNames((prevNames) => ({
                ...prevNames,
                [name]: file.name, // Store the file name for display
            }));
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name]; // Remove the error for the file field
                return updatedErrors;
            });
        }
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents any action when Enter is pressed
        }
    };
    const handleSubmitStep2 = async (e) => {
        e.preventDefault();
    
        // Reset errors
        setErrors({});
        let validationErrors = {};
    
        // Custom validation
        if (!formData.citizenship_status) {
            validationErrors.citizenship_status = ['Citizenship status is required'];
        }
        if (opendata) {
            if (!formData.awaiting_ead_card) {
                validationErrors.awaiting_ead_card = ['Please select if you are awaiting your EAD Card'];
            }
        }
        if (opendata2) {
            if (!formData.ead_card_applied_date) {
                validationErrors.ead_card_applied_date = ['EAD card application date is required'];
            }
        }
        if (!formData.citizenship_explanation) {
            validationErrors.citizenship_explanation = ['Please explain your citizenship status'];
        }else if (formData.citizenship_explanation.length < 3) {
            validationErrors.citizenship_explanation = ['Explanation must be at least 3 characters long'];
        }else if (formData.citizenship_explanation.length > 4000) {
            validationErrors.citizenship_explanation = ['Explanation must be less than 4000 characters long.'];
        }
        if (!formData.legally_authorized_to_work) {
            validationErrors.legally_authorized_to_work = ['Please select if you are legally authorized to work'];
        }
        if (!formData.ssn_issue_year) {
            validationErrors.ssn_issue_year = ['SSN issue year is required'];
        }
        if (opendata3) {
            if (!formData.work_authorization_expiry) {
                validationErrors.work_authorization_expiry = ['Work authorization expiry date is required'];
            }
        }
        if (opendata4) {
            if (!formData.renew_authorization_work) {
                validationErrors.renew_authorization_work = ['Work authorization renewal action details is required'];
            }else if (formData.renew_authorization_work.length < 3) {
                validationErrors.renew_authorization_work = ['Work authorization renewal action must be at least 3 characters long'];
            }else if (formData.renew_authorization_work.length > 4000) {
                validationErrors.renew_authorization_work = ['Work authorization renewal action must be less than 4000 characters long.'];
            }
        }
        if (!formData.laws_and_regulations) {
            validationErrors.laws_and_regulations = ['Please confirm the authorization to verify I-94'];
        }
    
        // Check if file fields are null or undefined only (skip strings)
        if (!formData.i94_copy && typeof formData.i94_copy !== 'string') {
            validationErrors.i94_copy = ['I-94 Copy is required'];
        }
        if (!opendata2) {
            if (!formData.proof_of_work_authorization && typeof formData.proof_of_work_authorization !== 'string') {
                validationErrors.proof_of_work_authorization = ['Proof of work authorization is required'];
            }
        }
    
        // If validation errors exist, set the errors state and return early
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setSubmitloader(true);
        try {
            const formDataToSend = new FormData();
    
            Object.keys(formData).forEach((key) => {
                const value = formData[key];
    
                // Only skip appending if the field is a string and is one of the file fields
                if (value !== null && !(typeof value === 'string' && ['i94_copy', 'proof_of_work_authorization', 'profile'].includes(key))) {
                    formDataToSend.append(key, value);
                }
            });
    
            // Make the API request
            const response = await apiService.postRequest('auth/register/step2', formDataToSend);
    
            // Check response for success
            if (response.success) {
                setErrors({});
                setErrorMessage('');
                if (response.reupdate === true) {
                    navigate('/registration/emergency-contact', { state: { fromNextPage: true } });
                } else {
                    navigate('/registration/emergency-contact');
                }
            } else {
                setErrorMessage('Registration failed. Please try again.');
                if (response.errors) {
                    setErrors(response.errors);
                }
            }
        } catch (error) {
            setErrorMessage('Registration failed. Please try again.');
        } finally {
            setSubmitloader(false); // Stop loader
        }
    };
    
      
  return (
    <Grid size={{lg:12}} container justifyContent="center" className="registerdetailsmain">
        <RegistrationLogout/>
        <Grid size={{lg:10,xs:11}} container alignItems="flex-start" justifyContent="center" className="regisdetailwhite">
            <Grid size={{lg:12}} container>
                <img onClick={() =>{navigate("/")}} src={require('../../assets/logo.svg').default} alt=''/>
                <Grid size={{lg:12,xs:12}} container justifyContent="center">
                    <Grid size={{lg:6,md:6,sm:8,xs:12}} container alignItems="center" justifyContent="space-between" className="progressspan">
                        <span className='activeprogress'>1</span>
                        <span className='activeprogress'>2</span>
                        <span>3</span>
                        <span>4</span>
                    </Grid>
                </Grid>
                {loading ? 
                    <div className='d-flex justify-content-center w-100'>
                        <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                    </div> :
                    <form className='w-100' onSubmit={handleSubmitStep2} onKeyDown={handleKeyDown}>
                        <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs">
                            <h5>Immigration Questions</h5>
                            <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                <span>Citizenship Status<small className='red_starimpo'>*</small></span>
                                <select name="citizenship_status" value={formData.citizenship_status} onChange={handleInputChange} >
                                    <option value="" disabled>Select Citizenship Status</option>
                                    {citizenshipStatuses.map((status) => (
                                    <option key={status.id} value={status.status}>
                                        {status.status}
                                    </option>
                                    ))}
                                </select>
                                {errors.citizenship_status && <p style={{color:"Red"}}>{errors.citizenship_status[0]}</p>}
                            </Grid>
                            {opendata &&
                            <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                <span>Are you waiting for your EAD Card to Arrive?<small className='red_starimpo'>*</small></span>
                                <select name="awaiting_ead_card" value={formData.awaiting_ead_card} onChange={handleInputChange} >
                                    <option value="" disabled>Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                                {errors.awaiting_ead_card && <p style={{color:"Red"}}>{errors.awaiting_ead_card[0]}</p>}
                            </Grid>
                            }
                            {opendata2 &&
                            <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                <span>When did you apply for your EAD Card?<small className='red_starimpo'>*</small></span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        className="date_pickermux"
                                        value={value2}
                                        onChange={handleDateChange}
                                        maxDate={dayjs(maxDate)}
                                        renderInput={(params) => <input {...params} />}
                                    />
                                </LocalizationProvider>
                                {errors.ead_card_applied_date && <p style={{color:"Red"}}>{errors.ead_card_applied_date[0]}</p>}
                            </Grid>
                            }
                            <Grid size={{sm:12, xs:12}} container flexDirection="column">
                                <span>Explain your citizenship status<small className='red_starimpo'>*</small></span>
                                <textarea maxLength="4001" placeholder='Describe' rows="10" name="citizenship_explanation" value={formData.citizenship_explanation} onChange={handleInputChange}/>
                                {errors.citizenship_explanation && <p style={{color:"Red"}}>{errors.citizenship_explanation[0]}</p>}
                            </Grid>
                            <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                <span>Are you legally authorized to work in United States ?<small className='red_starimpo'>*</small></span>
                                <select name="legally_authorized_to_work" value={formData.legally_authorized_to_work} onChange={handleInputChange}>
                                    <option value="" disabled>Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                                {errors.legally_authorized_to_work && <p style={{color:"Red"}}>{errors.legally_authorized_to_work[0]}</p>}
                            </Grid>
                            {opendata3 &&
                            <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                <span>When will your work authorization expire ?<small className='red_starimpo'>*</small></span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        className="date_pickermux"
                                        value={value}
                                        onChange={handleDateChange2}
                                        minDate={dayjs(maxDate)}
                                        renderInput={(params) => <input {...params} />}
                                    />
                                </LocalizationProvider>
                                {errors.work_authorization_expiry && <p style={{color:"Red"}}>{errors.work_authorization_expiry[0]}</p>}
                            </Grid>
                            }
                            {opendata4 &&
                            <Grid size={{sm:12, xs:12}} container flexDirection="column">
                                <span>What actions have you taken or are planning to take to renew your Work Authorization?<small className='red_starimpo'>*</small></span>
                                <textarea placeholder='Describe' maxLength="4001" rows="10" name="renew_authorization_work"
                                    value={formData.renew_authorization_work}
                                    onChange={handleInputChange}
                                    />
                                {errors.renew_authorization_work && <p style={{color:"Red"}}>{errors.renew_authorization_work}</p>}
                            </Grid>
                            }
                            <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                <span>Which year was your SSN Issued ?<small className='red_starimpo'>*</small></span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker className="date_pickermux" views={[ 'year']} maxDate={dayjs(maxDate)} value={value3}
                                        onChange={handleDateChange3} 
                                        renderInput={(params) => <input {...params} />}/>
                                </LocalizationProvider>
                                {/* <input type="text" pattern="\d*" maxlength="4" name="ssn_issue_year" value={formData.ssn_issue_year} onChange={handleInputChange}/> */}
                                {errors.ssn_issue_year && <p style={{color:"Red"}}>{errors.ssn_issue_year[0]}</p>}
                            </Grid>
                            <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                <span>Upload Most Recent I94 Copy<br/><small>(Upload only .jpg, .jpeg, .png, .pdf, .doc, .docx and with Maximum size as 5MB)</small><small className='red_starimpo'>*</small></span>
                                <div className="fileUploadInput">
                                <input
                                    type="file"
                                    accept=".jpg,.jpeg,.png,.pdf,.doc,.docx" 
                                    name="i94_copy"
                                    id="i94_copy"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }} // Hide the actual file input
                                />
                                <label htmlFor="i94_copy" style={{ cursor: 'pointer' }}>Upload</label>
                                <input
                                    type="text"
                                    value={fileNames.i94_copy || ''}
                                    readOnly
                                    placeholder="No file chosen"
                                />
                            </div>
                                {errors.i94_copy && <p style={{color:"Red"}}>{errors.i94_copy[0]}</p>}
                            </Grid>
                            <Grid size={{sm:12}} container className="my-3" flexDirection="column">
                                <span>I hereby authorize agents of TekPossible Consulting to access and verify the authenticity of my uploaded I-94 document through the official I-94 website (<a href="https://i94.cbp.dhs.gov/I94" target="_blank" rel="noopener noreferrer">https://i94.cbp.dhs.gov/I94</a>). I understand that this verification will be conducted in accordance with applicable laws and regulations.<small className='red_starimpo'>*</small></span>
                                <div className='d-flex align-items-center main_radioregister'>
                                    <input
                                        type="checkbox" 
                                        name="laws_and_regulations" 
                                        checked={formData.laws_and_regulations} 
                                        onChange={handleInputChange} 
                                    />I agree
                                </div>
                            {errors.laws_and_regulations && <p style={{color:"Red"}}>{errors.laws_and_regulations[0]}</p>}
                            </Grid>
                            {!opendata2 &&
                            <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                <span>Upload Proof of Work Authorization <br/><small>(Upload only .jpg, .jpeg, .png, .pdf, .doc, .docx and with Maximum size as 5MB)</small><small className='red_starimpo'>*</small></span>
                                <div className="fileUploadInput">
                                    <input
                                        type="file"
                                        accept=".jpg,.jpeg,.png,.pdf,.doc,.docx" 
                                        id="proof_of_work_authorization"
                                        name="proof_of_work_authorization"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }} // Hide the actual file input
                                    />
                                    <label htmlFor="proof_of_work_authorization" style={{ cursor: 'pointer' }}>Upload</label>
                                    <input
                                        type="text"
                                        value={fileNames.proof_of_work_authorization || ''}
                                        readOnly
                                        placeholder="No file chosen"
                                    />
                                </div>
                                {errors.proof_of_work_authorization && <p style={{color:"Red"}}>{errors.proof_of_work_authorization[0]}</p>}
                            </Grid>
                            }
                            <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                <span>Upload Profile Picture<br/><small>(Upload only .png, .jpeg, .gif and with Maximum size as 5MB)</small></span>
                                <div className="fileUploadInput">
                                <input
                                    type="file"
                                    id="profile"
                                    name="profile"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="profile" style={{ cursor: 'pointer' }}>Upload</label>
                                <input
                                    type="text"
                                    value={fileNames.profile || ''}
                                    readOnly
                                    placeholder="No file chosen"
                                />
                            </div>
                                {errors.profile && <p style={{color:"Red"}}>{errors.profile[0]}</p>}
                            </Grid>
                            {errorMessage && <p style={{ color: 'red',width: "100%" }}>{errorMessage}</p>}
                            <Grid size={{sm:12, xs:12}} container justifyContent="space-between" className="mt-5">
                                <button className='registersendbtn1' type="button" onClick={() => navigate('/registration/personal-questions', { state: { fromNextPage: true } })}>Previous</button>
                                <button className='registersendbtn2' type='submit'>{submitloader ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Save & Next'}</button>
                            </Grid>
                        </Grid>
                    </form>
                }
            </Grid>
        </Grid>
    </Grid>
  )
}

export default RegisterDetail2
