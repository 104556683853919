import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./admin.css";
import "./usermember.css";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiService } from '../../services/apiService';
import Select from 'react-select';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
function UserMemberAdd() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        dob: null,
        password: '',
        password_confirmation: '',
        roles: [],
        profile: null
    });
    const [errors, setErrors] = useState({});
    const [btnloading, setBtnLoading] = useState(false);
    const [errorsmessage, setErrorMessage] = useState('');
    const [rolesOptions, setRolesOptions] = useState([]);
    const [preview, setPreview] = useState(null);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisible2, setPasswordVisible2] = useState(false);

    // Toggle password visibility
    const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
    const togglePasswordVisibility2 = () => setPasswordVisible2(!passwordVisible2);

    // Fetch roles data from the API
    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await apiService.getRequest('/admin/roles-list');
                const options = response.data.map((role) => ({
                    value: role.id,  // Store role id instead of name
                    label: role.name,
                }));
                setRolesOptions(options);
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };
        fetchRoles();
    }, []);

    // Handle image selection and clear profile error if changed
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({ ...formData, profile: file });
            const reader = new FileReader();
            reader.onloadend = () => setPreview(reader.result);
            reader.readAsDataURL(file);

            // Clear profile error
            if (errors.profile) {
                setErrors({ ...errors, profile: '' });
            }
        }
    };

    // Clear specific errors when inputs are modified
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Clear the error for the changed field
        if (errors[name]) {
            setErrors({ ...errors, [name]: '' });
        }
    };
    const handleDateChange = (date) => {
        setFormData({ ...formData, dob: date });
        
        if (errors.dob) {
            setErrors({ ...errors, dob: '' });
        }
    };
    const handleRoleChange = (selectedOptions) => {
        setFormData({ ...formData, roles: selectedOptions });
        
        // Clear roles error
        if (errors.roles) {
            setErrors({ ...errors, roles: '' });
        }
    };

    const handlePhoneNumberChange = (e) => {
        const inputElement = e.target;
        const inputValue = inputElement.value;
        const cursorPosition = inputElement.selectionStart;

        let formattedInput = inputValue.replace(/\D/g, '');

        if (formattedInput.length > 3 && formattedInput.length <= 6) {
            formattedInput = formattedInput.replace(/(\d{3})(\d+)/, '($1) $2'); // Format (123) 456
        } else if (formattedInput.length > 6) {
            formattedInput = formattedInput.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3'); // Format (123) 456-7890
        } else if (formattedInput.length <= 3) {
            formattedInput = `(${formattedInput}`; // Start formatting with open bracket (123
        }

        setFormData({ ...formData, phone_number: formattedInput });
        
        // Clear phone number error
        if (errors.phone_number) {
            setErrors({ ...errors, phone_number: '' });
        }

        // Adjust cursor position if needed
        setTimeout(() => {
            inputElement.selectionStart = cursorPosition + (formattedInput.length - inputValue.length);
            inputElement.selectionEnd = inputElement.selectionStart;
        }, 0);
    };

    // Basic validation logic
    const validate = () => {
        const newErrors = {};
        if (!formData.first_name) newErrors.first_name = 'First name is required';
        if (!formData.last_name) newErrors.last_name = 'Last name is required';
        if (!formData.email) newErrors.email = 'Email is required';
        else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';
        if (!formData.phone_number) newErrors.phone_number = 'Phone number is required';
        else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formData.phone_number)) newErrors.phone_number = 'Phone number must be in the format (123) 456-7890';
        if (!formData.dob) newErrors.dob = 'Date of birth is required';
        if (!formData.password) newErrors.password = 'Password is required';
        if (!formData.password_confirmation) newErrors.password_confirmation = 'Please confirm your password';
        else if (formData.password !== formData.password_confirmation) newErrors.password_confirmation = 'Passwords do not match';
        if (formData.roles.length === 0) newErrors.roles = 'At least one role is required';

        const password = formData.password;
        const passwordFormat = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_#])[A-Za-z\d@$!%*?&_#]{8,}$/;
        if (!passwordFormat.test(password)) {
            newErrors.password = 'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and any one special character.';
        }

        const email = formData.email;
        const emailFormat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailFormat.test(email)) {
            newErrors.email = 'Please enter a valid email address.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return;

        const data = new FormData();
        data.append('first_name', formData.first_name);
        data.append('last_name', formData.last_name);
        data.append('email', formData.email);
        data.append('phone_number', formData.phone_number);
        if (formData.dob) {
            data.append('dob', dayjs(formData.dob).format('YYYY-MM-DD'));
        }
        data.append('password', formData.password);
        data.append('password_confirmation', formData.password_confirmation);
        
        if (formData.profile) {
            data.append('profile', formData.profile);
        }

        formData.roles.forEach(role => {
            data.append('roles[]', role.value);
        });

        setBtnLoading(true);
        try {
            const response = await apiService.postRequest('/admin/users', data);
            if (response && response.success === true) {
                navigate('/user/member');
                toast("Member added successfully!");
            } else {
                setErrorMessage('Registration failed. Please try again.');
                if (response.errors) {
                    setErrors(response.errors);
                }
            }
        } catch (error) {
            console.error('Error adding user:', error);
        } finally {
            setBtnLoading(false);
        }
    };
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{lg:12}} container justifyContent="center" className="main_jobseeker">
                <Grid size={{lg:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>User</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> <b onClick={()=>navigate("/user/member")}>User</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> <b onClick={()=>navigate("/user/member")}>Sub Admin</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Add Sub Admin</span>
                    </Grid>
                    <Grid size={{xs:12}} container className="editgrouphead my-4">
                        <h2>Add Sub Admin</h2>
                    </Grid>
                </Grid>
                <form className='row justify-content-center w-100' onSubmit={handleSubmit}>
                    <Grid size={{sm:11,xs:12}} container justifyContent="space-between" className="add_memberfields">
                        <Grid size={{sm:12,xs:12}} container alignItems="center" className="profile_uploadlab mb-5">
                            <div className="profile-upload-wrapper">
                                <label htmlFor="profile-upload-input" className="profile-upload-label">
                                    {preview ? (
                                        <img src={preview} alt="Profile Preview" className="profile-preview" />
                                    ) : (
                                        <div className="placeholder-icon">
                                            <img src={require('../../assets/cameraicon.svg').default} alt='Upload'/>
                                        </div>
                                    )}
                                </label>
                                <input
                                    id="profile-upload-input"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                />
                            </div>
                            <small>Upload Profile Picture</small>
                        </Grid>
                        <Grid size={{sm:5.5, xs:12}} container flexDirection="column" className="mb-4">
                            <span>Role<small className='red_starimpo'>*</small></span>
                            <Select
                                isMulti
                                name="roles"
                                value={formData.roles}
                                onChange={handleRoleChange}
                                options={rolesOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Choose Role..."
                            />
                            {errors.roles && <p style={{color:"red"}}>{errors.roles}</p>}
                        </Grid>
                        <Grid size={{sm:5.5, xs:12}} container flexDirection="column" className="memberfield_individual mb-4">
                            <span>Email Id<small className='red_starimpo'>*</small></span>
                            <input type="text"  name="email" value={formData.email} onChange={handleInputChange}/>
                            {errors.email && <p style={{color:"red"}}>{errors.email}</p>}
                        </Grid>
                        <Grid size={{sm:5.5, xs:12}} container flexDirection="column" className="memberfield_individual mb-4">
                            <span>First Name<small className='red_starimpo'>*</small></span>
                            <input type="text" name="first_name" value={formData.first_name} onChange={handleInputChange} onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}/>
                            {errors.first_name && <p style={{color:"red"}}>{errors.first_name}</p>}
                        </Grid>
                        <Grid size={{sm:5.5, xs:12}} container flexDirection="column" className="memberfield_individual mb-4">
                            <span>Last Name<small className='red_starimpo'>*</small></span>
                            <input type="text" name="last_name" value={formData.last_name} onChange={handleInputChange} onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}/>
                            {errors.last_name && <p style={{color:"red"}}>{errors.last_name}</p>}
                        </Grid>
                        <Grid size={{sm:5.5, xs:12}} container flexDirection="column" className="memberfield_individual mb-4">
                            <span>Date of Birth<small className='red_starimpo'>*</small></span>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    className="date_pickermux"
                                    value={formData.dob}
                                    onChange={handleDateChange}
                                    maxDate={dayjs().subtract(18, 'year').subtract(1, 'day')}
                                    renderInput={(params) => <input {...params} />}
                                />
                            </LocalizationProvider>
                            {errors.dob && <p style={{color:"red"}}>{errors.dob}</p>}
                        </Grid>
                        <Grid size={{sm:5.5, xs:12}} container flexDirection="column" className="memberfield_individual mb-4">
                            <span>Phone Number<small className='red_starimpo'>*</small></span>
                            <input type="text" name="phone_number" value={formData.phone_number} placeholder="(123) 456-7890" maxLength="14" onChange={handlePhoneNumberChange}/>
                            {errors.phone_number && <p style={{color:"red"}}>{errors.phone_number}</p>}
                        </Grid>
                        <Grid size={{xs:12}} container justifyContent="space-between">
                            <Grid size={{sm:5.5, xs:12}} container flexDirection="column" className="mb-4">
                                <span>Password<small className='red_starimpo'>*</small></span>
                                <div className='d-flex input_witheye'>
                                    <input
                                        name="password"
                                        type={passwordVisible ? 'text' : 'password'}
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        placeholder="Abcd123#"
                                        style={{ width: '100%', paddingRight: '40px' }}
                                    />
                                    <button type="button" onClick={togglePasswordVisibility}>
                                        {passwordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                    </button>
                                </div>
                                {errors.password && <p style={{color:"Red"}}>{errors.password}</p>}
                            </Grid>
                            <Grid size={{sm:5.5, xs:12}} container flexDirection="column" className="mb-4">
                                <span>Confirm Password<small className='red_starimpo'>*</small></span>
                                <div className='d-flex input_witheye'>
                                    <input
                                        name="password_confirmation"
                                        value={formData.password_confirmation}
                                        onChange={handleInputChange}
                                        type={passwordVisible2 ? 'text' : 'password'}
                                        placeholder="Abcd123#"
                                        style={{ width: '100%', paddingRight: '40px' }}
                                    />
                                    <button type="button" onClick={togglePasswordVisibility2}>
                                        {passwordVisible2 ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                    </button>
                                </div>
                                {errors.password_confirmation && <p style={{color:"red"}}>{errors.password_confirmation}</p>}
                            </Grid>
                        </Grid>
                    </Grid>
                    {errorsmessage && <p style={{ color: 'red' }}>{errorsmessage}</p>}
                    <Grid size={{sm:11,xs:12}} container justifyContent="flex-end" className="gap-3 my-3">
                        <button className='border_btnpink' onClick={()=>navigate("/user/member")}>Cancel</button>
                        <button type="submit" className='bgpink_btnpink addsubs_btn'>{btnloading ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Add Sub Admin'}</button>
                    </Grid>
                </form>
            </Grid>
        </div>
    </div>
  )
}

export default UserMemberAdd
