import React from 'react'
import Grid from '@mui/material/Grid2';
import "./terms.css";
import { useNavigate } from 'react-router-dom';
function PrivacyPolicy() {
    const navigate = useNavigate();
  return (
    <Grid size={{lg:12}} container justifyContent="center" className="termsconditionmain">
        <Grid size={{md:10,xs:11}} container justifyContent="flex-start" alignItems="flex-start" flexDirection="column">
            <img onClick={() =>{navigate("/")}} src={require('../../assets/logo.svg').default} alt=''/>
            <h2>Privacy Policy</h2>
            <h5>1. Introduction</h5>
            <small><b>Bench Track</b> ("We" or "Us") respects your privacy and is committed to protecting your personal data. This Privacy Policy outlines how we collect, use, and protect your information.</small>
            <h5>2. Data We Collect</h5>
            <small>Personal Information: We may collect personal information such as your name, email address, phone number, job title, and employer.</small>
            <small>Business Information: Information related to your business needs, workforce, and any documents or data you provide.</small>
            <small>Usage Data: Information about how you use our website, including IP address and cookies, where applicable.</small>
            <h5>3. Purpose of Data Collection</h5>
            <small>Service Delivery: To provide and manage our HR consulting services.</small>
            <small>Communication: To communicate with you regarding our services, updates, and other relevant information.</small>
            <small>Legal Compliance: To comply with applicable laws, regulations, and legal processes.</small>
            <h5>4. How We Share Your Data</h5>
            <small>Service Providers: We may share data with trusted third-party service providers who assist us in delivering our services (e.g., IT service providers).</small>
            <small>Legal Compliance: We may disclose your information if required by law or to enforce our agreements.</small>
            <h5>5. Data Security</h5>
            <small>We employ industry-standard security measures to protect your data from unauthorized access, loss, or misuse.</small>
            <small>However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.</small>
            <h5>6. Data Retention</h5>
            <small>We will retain your personal data for as long as necessary to fulfill the purposes for which it was collected or as required by law.</small>
            <h5>7. Your Rights</h5>
            <small>Access: You have the right to request access to your data.</small>
            <small>Correction: You may request correction of inaccurate data.</small>
            <small>Deletion: You have the right to request deletion of your data, subject to legal limitations.</small>
            <small>Objection: You may object to certain processing activities.</small>
            <h5>8. Cookies Policy</h5>
            <small>If our website uses cookies, you will be informed and given the option to accept or reject their use.</small>
            <h5>9. Changes to the Policy</h5>
            <small>We reserve the right to update this policy at any time. Changes will be posted on our website with a revised effective date.</small>
            <h5>10. Contact Us</h5>
            <small>For questions or concerns about this Privacy Policy, please contact us at [contact email or phone number].</small>
        </Grid>
    </Grid>
  )
}

export default PrivacyPolicy
