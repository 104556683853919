import React from 'react'
import Grid from '@mui/material/Grid2';
import "./footer.css";
import { useNavigate } from 'react-router-dom';

function Footer() {
    const navigate = useNavigate();
    const currentYear = new Date().getFullYear();
    const handleReloadnavigate = () => {
        window.location.reload(); // This will reload the page
        navigate("/");
    };
  return (
    <Grid size={{xs:12}} container justifyContent="center">
        <Grid size={{xs:11}} container justifyContent="center" className="py-5">
            <Grid size={{sm:12,xs:12}} container flexDirection="column" alignContent="center" alignItems="center" className="footer_logocontains">
                <img onClick={handleReloadnavigate} src={require('../../assets/logo.svg').default} alt=''/>
                <h4>United States (Texas)</h4>
                <span className='d-md-block d-none'>4425 W Airport Fwy, Suite #458 Irving, TX 75062 <br/>Tel: +1 (832) 568-4006</span>
                <span className='d-md-none d-block'>4425 W Airport Fwy, Suite #458 Irving,<br/> TX 75062, Tel: +1 (832) 568-4006</span>
            </Grid>
            <Grid size={{xs:12}} container className="footer_socials justify-content-md-end justify-content-center">
                <a href='https://www.instagram.com/tekpossible/' target="_blank" rel="noopener noreferrer"><img src={require('../../assets/instagram.svg').default} alt=''/></a>
                <a href='https://www.linkedin.com/company/tekpossible/' target="_blank" rel="noopener noreferrer"><img src={require('../../assets/linkedin-logo.svg').default} alt=''/></a>
            </Grid>
            <Grid size={{xs:12}} container alignItems="center" className="terms_spanfooter"> 
                <Grid size={{sm:6,xs:12}} container className="text-center text-md-start">
                    <span>Please visit our <a href='/termsofservice'>Terms of Service</a> for more details, and read our <a href='/privacypolicy'>Privacy Policy</a>.</span>
                </Grid>
                <Grid size={{sm:6,xs:12}} container className="justify-content-md-end justify-content-center">
                    <span>BenchTrack © {currentYear}. All Rights Reserved.</span>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
  )
}

export default Footer
