import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid2';
import { faAngleRight, faChevronLeft, faChevronRight, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { apiService } from '../../services/apiService';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Select from 'react-select';
function KnowledgeBase() {
    const navigate = useNavigate();
    
    const [roots, setRoots] = useState([]);
    const [loading, setLoading] = useState(true);
    const [savesbtnloading, setSavesbtnloading] = useState(false);
    const [paginatedata, setPaginateData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [name, setName] = useState('');
    const [selectedOwnedByGroup, setSelectedOwnedByGroup] = useState([]);
    const [selectedAccessGroups, setSelectedAccessGroups] = useState([]);
    const [error, setError] = useState('');
    const [errors1, setErrors1] = useState('');
    const [errors2, setErrors2] = useState('');
    const [rolesOptions, setRolesOptions] = useState([]);
    const [rolesrolelist, setRolesList] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState(null);
    const [show, setShow] = useState(false);
    const [selectedRootId, setSelectedRootId] = useState(null);
    const handleClose = () => setShow(false);


    const fetchRoots = useCallback(async (params = {}) => {
        setLoading(true);
        try {
            const queryString = new URLSearchParams(params).toString();
            const response = await apiService.getRequest(`/knowledge-article-bases?${queryString}`);
            if (response && response.success === true) {
                setRoots(response.data);
                setPaginateData(response.meta);
            }
        } catch (error) {
            console.error('Error fetching tab data:', error);
        } finally {
            setLoading(false);
        }
    }, []);
    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await apiService.getRequest('admin/roles-list');
                const options = response.data.map((role) => ({
                    value: role.id,  // Store role id instead of name
                    label: role.name,
                }));
                setRolesList(options);
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };
        fetchRoles();
    }, []);
    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await apiService.getRequest('admin/access-groups-list');
                const options = response.data.map((role) => ({
                    value: role.id,  // Store role id instead of name
                    label: role.name,
                }));
                setRolesOptions(options);
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };
        fetchRoles();
    }, []);
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        fetchRoots({ page: newPage });
    };

    const handleSearch = () => {
        setCurrentPage(1);
        fetchRoots({ search: searchQuery });
    };

    useEffect(() => {
        fetchRoots();
    }, [fetchRoots]);

    const handleInputChange = (e) => {
        setName(e.target.value);
        if (error) {
          setError(''); // Clear the error when the user starts typing
        }
    };

    const handleOwnedByGroupChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setSelectedOwnedByGroup(selectedValues);
    
        // Clear the Ownedby Group error when a group is selected
        if (selectedValues.length > 0 && errors1) {
            setErrors1('');  // Clear the error for ownedByGroup
        }
    };
    
    // Handle selection change for Access Groups
    const handleAccessGroupsChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setSelectedAccessGroups(selectedValues);
    
        if (selectedValues.length > 0 && errors2) {
            setErrors2('');  // Clear the error for ownedByGroup
        }
    };
    
    const handleSubmit = async () => {

        let valid = true;

    // Name validation
    if (name.trim() === '') {
        setError('Please enter a knowledge base name.');
        valid = false;
    }

    // Ownedby Group validation
    if (selectedOwnedByGroup.length === 0) {
        setErrors1('Please select at least one group for Ownedby Group.');
        valid = false;
    }

    // Access Groups validation
    if (selectedAccessGroups.length === 0) {
        setErrors2('Please select at least one group for Access Groups.');
        valid = false;
    }

    if (!valid) return;
        try {
            if (editMode && editId) {
                setSavesbtnloading(true);
                // Update the existing article
                const response = await apiService.patchRequest(`/knowledge-article-bases/${editId}`, {
                    name: name,
                    access_groups:selectedAccessGroups,
                    owned_by_groups:selectedOwnedByGroup,
                });
                if (response && response.success === true) {
                    setEditMode(false);
                    setEditId(null);
                    setName('');
                    setSelectedAccessGroups([]);
                    setSelectedOwnedByGroup([]);
                    fetchRoots();
                }else{
                    setError(response && response.message)
                }
            } else {
                setSavesbtnloading(true);
                const response = await apiService.postRequest('/knowledge-article-bases', {
                    name: name,
                    access_groups:selectedAccessGroups,
                    owned_by_groups:selectedOwnedByGroup,
                });
                if (response && response.success) {
                    setName('');
                    setSelectedAccessGroups([]);
                    setSelectedOwnedByGroup([]);
                    fetchRoots();
                }else{
                    setError(response && response.message)
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }finally{
            setSavesbtnloading(false);
        }
    };
    const handleCancel = () => {
        setName('');
        setEditMode(false);
        setEditId(null);
        setSelectedAccessGroups([]);
        setSelectedOwnedByGroup([]);
    };
    const handleEdit = async (id) => {
        try {
            const response = await apiService.getRequest(`/knowledge-article-bases/${id}`);
            if (response && response.success) {
                setName(response.data.name); // Populate the form with the existing data
                setSelectedAccessGroups(response.data.access_groups);
                setSelectedOwnedByGroup(response.data.owned_groups);
                setEditMode(true); // Enable edit mode
                setEditId(id); // Store the ID of the article being edited
            }
        } catch (error) {
            console.error('Error fetching article:', error);
        }
    };


    
    const handleShow = (rootId) => {
        setSelectedRootId(rootId);
        setShow(true);
    };

    const handleDelete = async () => {
        try {
            await apiService.deleteRequest(`/knowledge-article-bases/${selectedRootId}`); // Call DELETE API
            setRoots(roots.filter(roots => roots.id !== selectedRootId)); // Remove deleted member from state
            toast('Knowledge Base deleted successfully!');
        } catch (err) {
            toast.error("Failed to delete member");
        }
        handleClose();
    };

    const rows = roots.map((datas,index) => ({
        index:index+1,
        id: datas.id,
        name: datas.name,
        group: datas.owned_groups,
        access: datas.access_groups,
        
    }));
    const columns = [
        // {
        //     field: 'formatid',
        //     headerName: 'Jobseeker No',
        //     width: 250,
        //     renderCell: (params) => (
        //         <div className='d-flex align-items-center h-100'>
        //             <span className='cursetespoint' style={{color:"#C42B7D"}} onClick={() => handleShow(params.row.id)}>{params.row.formatid}</span>
        //         </div>
        //     ),
        //     filterable: true
        // },
        {
            field: 'index',
            headerName: '#',
            width: 80,
            filterable: true,
        },
        // {
        //     field: 'contactPhone',
        //     headerName: 'Phone',
        //     width: 250,
        //     filterable: true,
        //     renderCell: (params) => (
        //         <Box className="linklistview h-100 d-flex align-items-center">
        //             <Typography><span><a href={`tel:${params.row.contactPhone}`} target="_blank" rel="noopener noreferrer">{params.row.contactPhone}</a></span></Typography>
        //         </Box>
        //     )
        // },
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            filterable: true,
        },
        {
            field: 'group',
            headerName: 'Ownedby Group',
            width: 200,
            filterable: true,
        },
        {
            field: 'access',
            headerName: 'Access Groups',
            width: 200,
            filterable: true,
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 200,
            renderCell: (params) => (
                <Grid size={{ xs: 12 }} container alignItems="center" justifyContent="flex-start" className="gap-md-3 h-100 gap-2">
                    <button className='editbtnroot' onClick={() => handleEdit(params.row.id)}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    <button className='trashbtnroot' onClick={() => handleShow(params.row.id)}>
                        <FontAwesomeIcon icon={faTrashCan} />
                    </button>
                </Grid>
            ),
            filterable: true,
        },
    ];
    return (
        <div className='container-fluid px-0'>
            <div className='content_container'>
                <Grid size={{ sm: 12 }} container justifyContent="space-between" className="main_jobseeker">
                    <Grid size={{ sm: 12 }} container alignItems="center">
                        <Grid size={{ md: 8, xs: 12 }} container flexDirection="column" className="jobseekerhead">
                            <h3>Knowledge Base</h3>
                            <span><b onClick={() => navigate("/dashboard")}>Dashboard</b> 
                            <FontAwesomeIcon className='angleright' icon={faAngleRight} /> 
                            Manage Knowledge 
                            <FontAwesomeIcon className='angleright' icon={faAngleRight} /> 
                            Knowledge Base</span>
                        </Grid>
                    </Grid>
                    <Grid size={{ sm: 12 }} container justifyContent="space-between" className="flex-md-row-reverse">
                        <Grid size={{ sm: 4, xs: 12 }} container alignContent="flex-start" alignItems="flex-start" className="my-4 knowledge_baseboxes">
                            <h4>{editMode ? "Edit": "Add"} Knowledge Base</h4>
                            <Grid size={{ xs: 12 }} container justifyContent="flex-end" className="addlister_base mt-2 pt-2">
                                <label>Name<small className='red_starimpo'>*</small></label>
                                <input 
                                    placeholder="Knowledge Base Name"
                                    value={name}
                                    onChange={handleInputChange} 
                                    className='py-4'
                                />
                                {error && <p style={{ color: 'red', width: '100%' }}>{error}</p>}
                                <label>Ownedby Group<small className='red_starimpo'>*</small></label>
                                <Select
                                    isMulti
                                    name="ownedByGroup"
                                    value={rolesrolelist.filter(role => selectedOwnedByGroup.includes(role.value))}
                                    onChange={handleOwnedByGroupChange}
                                    options={rolesrolelist}
                                    className="basic-multi-select w-100"
                                    classNamePrefix="select"
                                    placeholder="Choose Ownedby Group..."
                                />
                                {errors1 && <p style={{ color: 'red', width: '100%' }}>{errors1}</p>}
                                <label>Access Groups<small className='red_starimpo'>*</small></label>
                                <Select
                                    isMulti
                                    name="accessGroups"
                                    value={rolesOptions.filter(role => selectedAccessGroups.includes(role.value))}
                                    onChange={handleAccessGroupsChange}
                                    options={rolesOptions}
                                    className="basic-multi-select w-100"
                                    classNamePrefix="select"
                                    placeholder="Choose Access Group..."
                                />
                                {errors2 && <p style={{ color: 'red', width: '100%' }}>{errors2}</p>}
                                <button onClick={handleSubmit}>
                                {savesbtnloading ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : <>{editMode ? 'Update' : 'Create'}</>}
                                </button>
                                {editMode && (
                                    <button className='cancel_btneditmode' onClick={handleCancel} style={{ marginLeft: '10px' }}>
                                        Cancel
                                    </button>
                                )}
                            </Grid>
                        </Grid>
                        <Grid size={{ md:7.8,sm: 6.8, xs: 12 }} container justifyContent="flex-end" className="my-4 knowledge_baseboxes">
                            <h4>Knowledge Bases</h4>
                            <Grid size={{ md: 8, xs: 12 }} container justifyContent="space-between" alignItems="center" className="mb-2">
                                <Grid size={{ xs: 12 }} container flexDirection="column">
                                    <div className='d-flex jobseekersearch my-3'>
                                        <div className='col-2'>
                                            <FontAwesomeIcon className='iconcol' icon={faMagnifyingGlass} />
                                        </div>
                                        <div className='col-md-7 col-6'>
                                        <input type='text' placeholder='search'
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}/>
                                        </div>
                                        <div className='col-md-3 col-4'>
                                            <button onClick={handleSearch}>Search</button>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            <div className='overflowwer_fullwidth w-100'>
                                {loading ? 
                                    <div className='d-flex justify-content-center w-100'>
                                        <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                                    </div> :<>
                                    {roots && roots.length > 0 ? (
                                        <Box sx={{ height: 650, width: '100%' }} className="datatable_jobpagi">
                                            <DataGrid
                                                rows={rows}
                                                columns={columns}
                                                disableSelectionOnClick
                                                components={{ Toolbar: GridToolbar }}
                                            />
                                        </Box>
                                    ) : (
                                        <div className="w-100 d-flex justify-content-center mt-4">
                                            <p>No data to display</p>
                                        </div>
                                    )}
                                </>}
                            </div>
                            <Grid size={{xs:12}} container justifyContent="center" className="mt-4 pagination_btn">
                                <button className='arrowpaginate'
                                    onClick={() => handlePageChange(currentPage - 1)} 
                                    disabled={currentPage === 1}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                                {Array.from({ length: paginatedata && paginatedata.last_page }, (_, index) => (
                                    <button 
                                        key={index} 
                                        onClick={() => handlePageChange(index + 1)}
                                        className={currentPage === index + 1 ? 'active' : ''}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                                <button className='arrowpaginate'
                                    onClick={() => handlePageChange(currentPage + 1)} 
                                    disabled={currentPage === paginatedata.last_page}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this Knowledge Base?</Modal.Body>
                    <Modal.Footer>
                        <button className='modal_cancel' onClick={handleClose}>
                            Cancel
                        </button>
                        <button className='modal_delete' onClick={handleDelete}>
                            Delete
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
}

export default KnowledgeBase;
