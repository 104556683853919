import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./knowledge.css";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { apiService } from '../../services/apiService';
import { toast } from 'react-toastify';

function Discursion() {
    const location = useLocation();
    const currentPath = location.pathname;
    const navigate = useNavigate();
    const [catesNames,setCateNames] = useState('');
    const [articleDetail, setArticleDetail] = useState([]);
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [commenteddata, setCommentedData] = useState([]);
    const [errors, setErrors] = useState({});
    const [comment, setComment] = useState('');
    const [replyComments, setReplyComments] = useState({});
    const [activeCommentId, setActiveCommentId] = useState(null);
    useEffect(() => {
        const fetchPreviewData = async () => {
            try {
                const response = await apiService.getRequest(`knowledge-article/${id}`);
                if (response && response.success) {
                    setCateNames(response.data.subcategory);
                    setArticleDetail(response.data);
                }
            } catch (error) {
                console.error('Error fetching article data:', error);
            }
        };

        fetchPreviewData();
    }, [id]);

    const fetchPreviewData = useCallback(async () => {
        try {
            const response = await apiService.getRequest(`knowledge-articles/${id}/discussions`);
            if (response && response.success === true) {
                setCommentedData(response.data);
            }
        } catch (error) {
            console.error('Error fetching group data:', error);
        } finally {
            setLoading(false);
        }
    }, [id]); // Dependency on slug

    // Call fetchPreviewData on mount and whenever slug changes
    useEffect(() => {
        fetchPreviewData();
    }, [fetchPreviewData]);

    const handleSubmit = async (e) => {
        e.preventDefault(); 
        const newErrors = {};
        if (comment.trim() === '') {
        newErrors.comment = "Please enter a comment.";
        }

        // If there are errors, update the errors state and do not submit
        if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
        }

        try{
            const jsonParams = {
                "comment": comment,
            }
            const response = await apiService.postRequest(`knowledge-articles/${id}/discussions`,jsonParams);
            if(response && response.success === true){
                toast("Comment Submitted");
                setComment("");
                fetchPreviewData();
            }
            }catch (error) {
                console.error('Error fetching data:', error);
        }
    };

    const handleReplyChange = (e, commentId) => {
        const { value } = e.target; // Get the current input value
        console.log('Updating reply for comment ID:', commentId, 'to:', value); // Debugging output

        // Update the state to reflect the current textarea input
        setReplyComments((prevReplies) => ({
            ...prevReplies,
            [commentId]: value, // Set the value for the specific comment
        }));
    };

    const handleReplyClick = useCallback((commentId) => {
        setActiveCommentId(activeCommentId === commentId ? null : commentId);
    }, [activeCommentId]);

    const handleSubmitreply = useCallback(async (e, parentId) => {
        e.preventDefault(); // Prevent default form submission

        const replyText = replyComments[parentId]; // Get the current reply text
        if (!replyText) {
            console.log("Reply text is empty."); // Debugging output if empty
            return; // Prevent submission if empty
        }

        try {
            const jsonParams = {
                comment: replyText,
                parent_id: parentId,
            };
            const response = await apiService.postRequest(`knowledge-articles/${id}/discussions`, jsonParams);
            if (response && response.success === true) {
                toast("Comment Submitted");
                setReplyComments((prev) => ({
                    ...prev,
                    [parentId]: ''
                }));
                setActiveCommentId(null);
                fetchPreviewData();
            }
        } catch (error) {
            console.error('Error submitting reply:', error);
        }
    }, [replyComments, id, fetchPreviewData]);

  return (

    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    {articleDetail && articleDetail.status === "under_approval" ? (
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Under Approval</h3>
                        <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                    </Grid>
                    ):""}
                    {articleDetail && articleDetail.status === "draft" ? (
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Edit Draft</h3>
                        <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                    </Grid>
                    ):""}
                    {articleDetail && articleDetail.status === "published" ? (
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Published</h3>
                        <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                    </Grid>
                    ):""}
                    {articleDetail && articleDetail.status === "retired" ? (
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Retired</h3>
                        <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                    </Grid>
                    ):""}
                </Grid>
                <Grid size={{xs:12}} container justifyContent="flex-start" className="statusselectbtn mt-4">
                    <div className='knowledgetab_btnredirect'>
                        <button onClick={() =>{navigate(`/knowledge/edit-article/${id}`)}}>Article</button>
                        {articleDetail && articleDetail.status === "draft" ? "":
                        <button onClick={() =>{navigate(`/knowledge/under-approval/show/${id}`)}}>Approvals</button>}
                        <button onClick={() =>{navigate(`/knowledge/under-approval/history/${id}`)}}>History</button>
                        <button onClick={() =>{navigate(`/knowledge/attachments/${id}`)}}>Attachments</button>
                        <button className={currentPath.startsWith("/knowledge/discussions") ? "active_tabbtn" : ""} onClick={() =>{navigate(`/knowledge/discursion/`)}}>Discussions</button>
                        <button onClick={() =>{navigate(`/knowledge/versions/${id}`)}}>Versions</button>
                    </div>
                </Grid>
                <form className='w-100 mt-3' onSubmit={handleSubmit}>
                    <Grid size={{sm:12}} container justifyContent="flex-end" className="mt-4">
                        <textarea 
                        style={{background:"#fff"}}
                        className='comment_textarea' 
                        placeholder="Discription...." 
                        value={comment} 
                        onChange={(e) => setComment(e.target.value)} 
                        />
                        <div className='w-100'>
                            {errors.comment && <p style={{color:"red"}}>{errors.comment}</p>}
                        </div>
                        <button type="submit" className='submit_btn'>Submit</button>
                    </Grid>
                </form>
                <Grid size={{sm:12}} container justifyContent="center" className="mt-4 previous_comments discurssionoverflow">
                {loading ? 
                <div className='d-flex justify-content-center w-100 mt-5 pt-5'>
                    <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                </div> :<>
                    {commenteddata && commenteddata.map((comment) => (
                        <Grid size={{ sm: 12 }} key={comment.id} container alignItems="center" justifyContent="space-between" className="mb-4 reply_comment_btn dicussion_heightset">
                        <small className='pink_small'>{comment.user}</small>
                        <small className='datetime_small'>{comment.created_at}</small>
                        <span>{comment.comment}</span>
                        <button className='reply_btntext' onClick={() => handleReplyClick(comment.id)}>
                            {activeCommentId === comment.id ? "Cancel" : "Reply"}
                        </button>
                
                        {activeCommentId === comment.id && (
                            <div className='d-flex flex-column w-100 mb-2'>
                                <textarea
                                    value={replyComments[comment.id] || ''}
                                    onChange={(e) => handleReplyChange(e, comment.id)}
                                    placeholder="Write your reply..."
                                />
                                <button style={{opacity:!replyComments[comment.id]?.trim() ? "0.8":"1"}} disabled={!replyComments[comment.id]?.trim()}  onClick={(e) => handleSubmitreply(e, comment.id)}>Send Reply</button>
                            </div>
                        )}
                        {comment.replies && comment.replies.map((reply) => (
                            <Grid size={{ sm: 12 }} key={reply.id} container alignItems="center" justifyContent="space-between" className="mb-4 ps-5 reply_comment_btn">
                                <small className='pink_small'>{reply.user} <small>(@{reply.parentUser})</small></small>
                                <small className='datetime_small'>{reply.created_at}</small>
                                <span>{reply.comment}</span>
                                <button className='reply_btntext' onClick={() => handleReplyClick(reply.id)}>
                                    {activeCommentId === reply.id ? "Cancel" : "Reply"}
                                </button>
                        
                                {activeCommentId === reply.id && (
                                    <div className='d-flex flex-column w-100'>
                                        <textarea
                                            value={replyComments[reply.id] || ''}
                                            onChange={(e) => handleReplyChange(e, reply.id)}
                                            placeholder="Write your reply..."
                                        />
                                        <button style={{opacity:!replyComments[reply.id]?.trim() ? "0.8":"1"}} disabled={!replyComments[reply.id]?.trim()} onClick={(e) => handleSubmitreply(e, reply.id)}>Send Reply</button>
                                    </div>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                    ))}
                </>}
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default Discursion
