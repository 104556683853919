import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { faAngleRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import "./knowledge.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import { faCalendar, faEye, faUser } from '@fortawesome/free-regular-svg-icons';
import { Accordion } from 'react-bootstrap';
import { apiService } from '../../services/apiService';
function KnowledgeArticlePreview() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [previewData, setPreviewData] = useState([]);
    const [docudatas, setDocumentdatas] = useState([]);
    const { id } = useParams();
    useEffect(() => {
        const fetchPreviewData = async () => {
            setLoading(true)
          try {
            const response = await apiService.getRequest(`knowledge-article/${id}`);
            if(response && response.success === true){
                setPreviewData(Array.isArray(response.data) ? response.data : [response.data]);
                setDocumentdatas(response.data);
            }
          } catch (error) {
            console.error('Error fetching group data:', error);
          }finally{
            setLoading(false)
          }
        };
    
        fetchPreviewData();
      }, [id]);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const closeElement = () => {
    setIsVisible(true);
  };

    const sanitizeAndDisplayHTML = (html) => {
        const sanitizedHTML = html
        return sanitizedHTML;
    };
  return (
    <div className='container-fluid px-0'>

        {loading ? 
            <div className='d-flex justify-content-center w-100 mt-5 pt-5'>
                <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
            </div> :<>
            {previewData && previewData.map((article) => (<div key={article.id} className='w-100'>
                <div className={isVisible ? 'hide_mobitr' : 'show_mobitr'}>
                    <div className='klgcategory_main'>
                        <div className='d-flex w-100 justify-content-end'>
                            <button className='d-md-none closecat_btn' onClick={closeElement}><FontAwesomeIcon icon={faXmark} /></button>
                        </div>
                        <div className='d-flex flex-column'>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header><img src={require('../../assets/chevron-down.svg').default} alt=''/>{article.subcategory.category_name}</Accordion.Header>
                                    <Accordion.Body>
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header><img src={require('../../assets/chevron-down.svg').default} alt=''/>{article.subcategory.knowledge_base}</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className='d-flex flex-column'>
                                                        <span>{article.subcategory.name}</span>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        
                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>
                        </div>
                    </div>
                </div>
                <div className='content_container_articals'>
                    <Grid size={{sm:12}} container className="main_jobseeker" style={{background:"#fff"}}>
                        <Grid size={{sm:12}} container alignItems="center">
                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                <h3>Knowledge Article</h3>
                                <span>Knowledge Article<FontAwesomeIcon className='angleright' icon={faAngleRight} /> {article.base.name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {article.subcategory.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {article.subcategory.name}</span>
                                <button onClick={toggleVisibility} className='d-md-none main_category_btn'>
                                    Category
                                </button>
                            </Grid>
                            <Grid size={{xs:12}} container justifyContent="flex-end">
                                <button className='registersendbtn1' onClick={() => navigate(`/knowledge/edit-article/${article.id}`)}>Close Preview</button>
                            </Grid>
                        </Grid>
                        <Grid size={{sm:12}} container justifyContent="center" className="mt-4">
                            <Grid size={{sm:12}} container justifyContent="center" className="knowledge_lister">
                                <h4>{article.title}</h4>
                                <Grid size={{xs:12}} container className="span_griders mb-4">
                                    <span><FontAwesomeIcon icon={faUser} className='icon_kn'/><small>Authored by :</small>{article.author.name}</span>
                                    <span><FontAwesomeIcon icon={faEye} className='icon_kn'/>{article.view_count} Views</span>
                                    <span><FontAwesomeIcon icon={faCalendar} className='icon_kn'/>{article.created_at}</span>
                                </Grid>
                                <Grid size={{xs:12}} container className="gap-3 mb-3">
                                {Array.isArray(docudatas.documents) ? (
                                    docudatas.documents.map(docum => (
                                    <Grid key={docum.id} size={{lg:2.8,md:2.8,sm:3.8,xs:12}} alignItems="center" container className="documentcards">
                                        {docum && (docum.extension ==="docx" || docum.extension ==="word") ?
                                        <img src={require('../../assets/svg2.svg').default} alt={docum.name} /> : docum && docum.extension ==="pdf" ? <img src={require('../../assets/svg1.svg').default} alt={docum.name} /> : docum && docum.extension ==="csv" ? <img src={require('../../assets/svg4.svg').default} alt={docum.name} /> : <img src={require('../../assets/svg3.svg').default} alt={docum.name}/>
                                        }
                                        <a href={docum.file_path} target="_blank" rel="noopener noreferrer">{docum.document_name}</a>
                                    </Grid>
                                    ))
                                ) : (
                                    ""
                                )}
                                </Grid>
                                <div className='w-100 caliberifontsset' dangerouslySetInnerHTML={{ __html: sanitizeAndDisplayHTML(article.content) }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
        </div>
        ))
        }
    </>}
    </div>
  )
}

export default KnowledgeArticlePreview
