import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../services/apiService';
const profileImage = require('../../assets/defaultprofile.svg').default;
function RegistrationLogout() {
    const navigate = useNavigate();
    const [userprofile, setUserProfile] = useState(null);
    const userId = localStorage.getItem('userid');
    useEffect(() => {
        const fetchData = async () => {
            const response = await apiService.postRequest(`profile`);
            if (response.success) {
                setUserProfile(response.user);
                if (response && response.pending_signup === false) {
                    navigate("*")
                }
            }
        };
        fetchData();
    }, [userId,navigate]);
    const handleLogout = async () => {
        try {
            await apiService.postRequest('/auth/logout');
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            localStorage.removeItem('userid');
            localStorage.removeItem('usernames');
            localStorage.removeItem('permissions');
            localStorage.removeItem('hasReloaded');
            localStorage.removeItem('authenti');
            localStorage.removeItem('notifications');
            navigate('/login');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };
  return (
    <Grid size={{xs:12}} container justifyContent="flex-end" className="mb-4">
        <img className='fake_profile' src={(userprofile && userprofile.profile_picture) || profileImage} alt=""/>
        <Dropdown>
            <Dropdown.Toggle className='drop_btnlogout ps-0 d-flex align-items-center' >
            <font className="d-md-block d-none">{userprofile?.first_name || ''}</font>
            <font className="d-md-none d-block">{userprofile?.first_name?.length > 5 ? `${userprofile.first_name.slice(0, 5)}...` : userprofile?.first_name || ''}</font>
            </Dropdown.Toggle>
            <Dropdown.Menu className='px-2'>
                <button className='suspent_btn' onClick={handleLogout}>Logout</button>
            </Dropdown.Menu>
        </Dropdown>
    </Grid>
  )
}

export default RegistrationLogout
