import React from 'react'
import Grid from '@mui/material/Grid2';
import "./terms.css";
import { useNavigate } from 'react-router-dom';
function TermsofServices() {
    const navigate = useNavigate();
  return (
    <Grid size={{lg:12}} container justifyContent="center" className="termsconditionmain">
        <Grid size={{md:10,xs:11}} container justifyContent="flex-start" alignItems="flex-start" flexDirection="column">
            <img onClick={() =>{navigate("/")}} src={require('../../assets/logo.svg').default} alt=''/>
            <h2>Terms and Conditions</h2>
            <h5>1. Introduction</h5>
            <small>These Terms and Conditions ("Terms") govern the use of <b>Bench Track</b>’s HR consulting services ("Services"). By engaging with <b>Bench Track</b>, you ("Client") agree to these Terms.</small>
            <h5>2. Definitions</h5>
            <small>"Consultant" refers to <b>Bench Track</b>, including all employees and contractors providing HR consulting services.</small>
            <small>"Services" refers to the HR consulting services provided by the Consultant.</small>
            <small>"Client" refers to the individual or company engaging the Consultant's services.</small>
            <h5>3. Services Provided</h5>
            <small><b>Bench Track</b> provides HR consulting services, including but not limited to talent acquisition, workforce management, HR strategy development, training programs, employee engagement initiatives, and related services as outlined in the agreement between the Consultant and Client.</small>
            <h5>4. Client Responsibilities</h5>
            <small>Provision of Accurate Information: The Client agrees to provide accurate, current, and complete information required for the Services.</small>
            <small>Timely Cooperation: The Client must cooperate with the Consultant and provide access to relevant personnel, data, and facilities as needed.</small>
            <h5>5. Fees and Payment Terms</h5>
            <small>Fees: The Client agrees to pay the fees outlined in the service agreement.</small>
            <small>Invoices: Invoices will be issued [monthly/quarterly] or as agreed upon in the service agreement.</small>
            <small>Payment Terms: Payment is due within [number of days] days of the invoice date. Late payments may incur a late fee of [percentage or amount].</small>
            <small>Additional Costs: Any expenses incurred during service delivery (e.g., travel expenses) must be pre-approved by the Client and will be invoiced separately.</small>
            <h5>6. Confidentiality</h5>
            <small>Confidential Information: Both parties agree to maintain the confidentiality of any non-public information provided by the other party.</small>
            <small>Exclusions: Confidentiality obligations do not apply to information that is publicly available or required to be disclosed by law.</small>
            <h5>7. Intellectual Property</h5>
            <small>Ownership: All deliverables, reports, documents, or materials created during the provision of the Services remain the intellectual property of the Consultant unless otherwise agreed in writing.</small>
            <small>Usage Rights: The Client is granted a non-exclusive, non-transferable license to use deliverables for internal purposes only.</small>
            <h5>8. Term and Termination</h5>
            <small>Term: These Terms are effective as long as Services are provided.</small>
            <small>Termination: Either party may terminate the agreement by providing [number of days] days' written notice.</small>
            <small>Termination for Breach: Immediate termination may occur if either party materially breaches these Terms and fails to cure the breach within [number of days] days of written notice.</small>
            <h5>9. Limitation of Liability</h5>
            <small>The Consultant is not liable for any indirect, consequential, or incidental damages arising from the Services.</small>
            <small>The Consultant’s total liability is limited to the fees paid by the Client in the [number] months preceding the incident.</small>
            <h5>10. Indemnification</h5>
            <small>The Client agrees to indemnify and hold the Consultant harmless from any claims, losses, or damages arising from Client-provided data, actions, or inactions.</small>
            <h5>11. Governing Law and Dispute Resolution</h5>
            <small>These Terms are governed by the laws of [State/Country]. Any disputes shall be resolved through [mediation/arbitration/court proceedings] in [City/State].</small>
        </Grid>
    </Grid>
  )
}

export default TermsofServices
