import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./login.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { apiService } from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
function Login() {
 
  const [submitloader, setSubmitloader] = useState(false);
    const navigate = useNavigate();
    const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setPasswordVisible(!passwordVisible);
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [failedAttempts, setFailedAttempts] = useState(0);
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token === "undefined") {
      // If token exists, redirect to the dashboard
    } else if (token){
        navigate('/dashboard');
    }
  }, [navigate]);
  // Function to handle form submission
  const handleLogin = async (e) => {
    e.preventDefault();
    setSubmitloader(true);
    try {
      const loginResponse = await apiService.postRequest('auth/login', {
        email: email,      
        password: password,
      });
      if (loginResponse && loginResponse.success === true) {
        const { token } = loginResponse.data;
        const { user_type } = loginResponse.data;
        const { user_id } = loginResponse.data;
        localStorage.setItem('token', token);
        localStorage.setItem('role', user_type);
        localStorage.setItem('userid', user_id);
        if (loginResponse && loginResponse.pending_signup === true){
          if (loginResponse && loginResponse.pending_step === "personal_questions"){
            navigate('/registration/personal-questions');
          }else if (loginResponse && loginResponse.pending_step === "immigration"){
            navigate('/registration/immigration-questions');
          }else if (loginResponse && loginResponse.pending_step === "emergency"){
            navigate('/registration/emergency-contact');
          }else{
            navigate('/registration/referral-information');
          }
        } else if (loginResponse && !loginResponse.pending_signup === true){
            if(user_type === "job_seeker"){
                navigate('/dashboard');
              }
              else if(user_type === "admin"){
                navigate('/dashboard');
              }
              else if(user_type === "sub_admin"){
                navigate('/dashboard');
              }
        }
      }else {
        setErrorMessage(loginResponse.message || 'Invalid email or password. Please try again.');
        setFailedAttempts((prev) => prev + 1); // Increment failed attempts
        if (failedAttempts + 1 >= 3) {
          navigate('/forgotpassword'); // Redirect to forgot password page on 3rd failed attempt
        }
      }
    } catch (error) {
      setErrorMessage('Invalid email or password. Please try again.');
    }finally {
      setSubmitloader(false); // Stop loader
    }
  };
  return (
    <Grid size={{xs:12}} container justifyContent="center" className="main_loginbg">
        <Grid size={{lg:11}} container justifyContent="space-evenly" alignItems="center">
            <Grid size={{md:5,xs:11}} container flexDirection="column" alignItems="flex-start" justifyContent="flex-end" className="left_inputlogin">
                <img onClick={() =>{navigate("/")}} src={require('../../assets/shortlogo.svg').default} alt=''/>
                <h2>Login</h2>
                <form className='d-flex w-100 flex-column' onSubmit={handleLogin}>
                    <span>Email<small className='red_starimpo'>*</small></span>
                    <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                    <span>Password<small className='red_starimpo'>*</small></span>
                    <div className='d-flex input_witheye'>
                        <input
                            id="password" value={password} onChange={(e) => setPassword(e.target.value)} required
                            type={passwordVisible ? 'text' : 'password'}
                            placeholder="Enter your password"
                            style={{ width: '100%', paddingRight: '40px' }}
                        />
                        <button type="button" onClick={togglePasswordVisibility}>
                            {passwordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                        </button>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <a href='/forgotpassword'>Forgot Password</a>
                    </div>
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    <button type="submit">{submitloader ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Login'}</button>
                </form>
                <small className='login_small2'>Don’t have an account ? <a href='/loginselect'>Register Here</a></small>
            </Grid>
            <Grid size={{md:5,sm:12}} container flexDirection="column" alignItems="center" justifyContent="flex-end" className="leniarrightlogin">
                <h1>Your Career Awaits</h1>
                <h5>Join our vibrant community of <br/> job seekers and employers.</h5>
                <img src={require('../../assets/loginlady.png')} alt=''/>
            </Grid>
        </Grid>
    </Grid>
  )
}

export default Login
