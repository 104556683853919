import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from '../pages/Login/Login';
import Loginselect from '../pages/Login/Loginselect';
import Homepage from '../pages/homepage/Homepage';
import RegisterDetail1 from '../pages/Login/RegisterDetail1';
import RegisterDetail2 from '../pages/Login/RegisterDetail2';
import RegisterDetail3 from '../pages/Login/RegisterDetail3';
import RegisterDetail4 from '../pages/Login/RegisterDetail4';
import TermsandCondition from '../pages/Terms/TermsandCondition';
import UserJobSeekers from '../pages/admin/UserJobSeekers';
import Jobseekerdetails from '../pages/admin/Jobseekerdetails';
import Dashboard from '../pages/user/Dashboard';
import ForgetPassword from '../pages/Login/ForgetPassword';
import ResetPassword from '../pages/Login/ResetPassword';
import { useState } from 'react';
import UserGroup from '../pages/admin/UserGroup';
import UserGroupEdit from '../pages/admin/UserGroupEdit';
import UserMember from '../pages/admin/UserMember';
import UserGroupAdd from '../pages/admin/UserGroupAdd';
import UserMemberAdd from '../pages/admin/UserMemberAdd';
import UserMemberEdit from '../pages/admin/UserMemberEdit';
import JobseekerProfile from '../pages/admin/JobseekerProfile';
import RegisterIds from '../pages/Login/RegisterIds';
import KnowledgeArticle from '../pages/Knowledge/KnowledgeArticle';
import KnowledgeArticleView from '../pages/Knowledge/KnowledgeArticleView';
import KnowledgeBase from '../pages/Knowledge/KnowledgeBase';
import KnowledgeCategory from '../pages/Knowledge/KnowledgeCategory';
import KnowledgeSubCategory from '../pages/Knowledge/KnowledgeSubCategory';
import Draft from '../pages/Knowledge/Draft';
import DraftAdd from '../pages/Knowledge/DraftAdd';
import KnowledgeArticlePreview from '../pages/Knowledge/KnowledgeArticlePreview';
import DraftEdit from '../pages/Knowledge/DraftEdit';
import UnderApproval from '../pages/Knowledge/UnderApproval';
import UnderApprovalShow from '../pages/Knowledge/UnderApprovalShow';
import UnderApprovalHistory from '../pages/Knowledge/UnderApprovalHistory';
import Published from '../pages/Knowledge/Published';
import Retired from '../pages/Knowledge/Retired';
import Allheader from '../components/common/Allheader';
import Attachments from '../pages/Knowledge/Attachments';
import Discursion from '../pages/Knowledge/Discursion';
import Notifications from '../components/common/Notifications';
import VersionsArticle from '../pages/Knowledge/VersionsArticle';
import TermsofServices from '../pages/Terms/TermsofServices';
import PrivacyPolicy from '../pages/Terms/PrivacyPolicy';

const AppRoutes = () => {
  const [formData, setFormData] = useState({});
  const [role, setRole] = useState(localStorage.getItem('role'));

  const handleFormDataChange = (stepData) => {
    setFormData(prevData => ({ ...prevData, ...stepData }));
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setRole(localStorage.getItem('role'));
    };

    // Add event listener to detect localStorage changes
    window.addEventListener('storage', handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const AuthenticatedRoutes = () => (
    <>
      <Allheader />
      <Routes>
        
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/notification" element={<Notifications />} />
        <Route path="/knowledge" element={<KnowledgeArticle />} />
        <Route path="/knowledge-article/:slug" element={<KnowledgeArticleView />} />
        
        {role && (role === "admin" || role === "sub_admin") && (
          <>
            <Route path="/user/group" element={<UserGroup />} />
            <Route path="/user/group-add" element={<UserGroupAdd />} />
            <Route path="/user/member" element={<UserMember />} />
            <Route path="/user/member-add" element={<UserMemberAdd />} />
            <Route path="/user/member-edit/:id" element={<UserMemberEdit />} />
            <Route path="/user/group-edit/:id" element={<UserGroupEdit />} />
            <Route path="/user/jobseekers" element={<UserJobSeekers />} />
            <Route path="/user/jobseeker-details/:id" element={<Jobseekerdetails />} />
            <Route path="/user/jobseeker-profile/:id" element={<JobseekerProfile />} />
            <Route path="/knowledge/draft" element={<Draft />} />
            <Route path="/knowledge/attachments/:id" element={<Attachments />} />
            <Route path="/knowledge/under-approval" element={<UnderApproval />} />
            <Route path="/knowledge/published" element={<Published />} />
            <Route path="/knowledge/retired" element={<Retired />} />
            <Route path="/knowledge/under-approval/show/:id" element={<UnderApprovalShow />} />
            <Route path="/knowledge/under-approval/history/:id" element={<UnderApprovalHistory />} />
            <Route path="/knowledge/versions/:id" element={<VersionsArticle />} />
            <Route path="/knowledge/discussions/:id" element={<Discursion />} />
            <Route path="/knowledge/add-draft" element={<DraftAdd />} />
            <Route path="/knowledge/edit-article/:id" element={<DraftEdit />} />
            <Route path="/knowledge/preview/:id" element={<KnowledgeArticlePreview />} />
            <Route path="/knowledge/root" element={<KnowledgeBase />} />
            <Route path="/knowledge/category" element={<KnowledgeCategory />} />
            <Route path="/knowledge/sub-category" element={<KnowledgeSubCategory />} />
          </>
        )}
        {/* Redirect to login if route is not found */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </>
  );

  return (
    <div className='container-fluid px-0'>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/loginselect" element={<Loginselect />} />
        <Route path="/forgotpassword" element={<ForgetPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/termsandcondition" element={<TermsandCondition />} />
        <Route path="/termsofservice" element={<TermsofServices />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/registration" element={<RegisterIds />} />
        <Route path="/registration/personal-questions" element={<RegisterDetail1 formData={formData} onFormDataChange={handleFormDataChange} />} />
        <Route path="/registration/immigration-questions" element={<RegisterDetail2 formData={formData} onFormDataChange={handleFormDataChange} />} />
        <Route path="/registration/emergency-contact" element={<RegisterDetail3 formData={formData} onFormDataChange={handleFormDataChange} />} />
        <Route path="/registration/referral-information" element={<RegisterDetail4 formData={formData} onFormDataChange={handleFormDataChange} />} />
        {/* Private Routes */}
        <Route path="/*" element={<AuthenticatedRoutes />} />
      </Routes>
    </div>
  );
};

export default AppRoutes;
